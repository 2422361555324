//REACTJS
import React, { useEffect, useState } from 'react';
//GATSBY
import { navigate } from 'gatsby';
//FORMIK & YUP
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//APPSYNC-MUTATIONS
import { handleCreateUser } from '../appsync_functions/mutations';
//APPSYNC-QUERIES
import { fetchGetOneUser } from '../appsync_functions/queries';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingUSERNAME, settingISSIGNEDIN, settingDISPLAY_USERNAME_PAGE, settingROLE } from '../context/contextapi';

// Toast
import { Toaster, toast } from 'react-hot-toast';
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

export default function Username() {
    const [{ username }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [fetchUserLoading, seFetchUserLoading] = useState(true)

    const initialValues = {
        username: "",
        role: ""
    }


    const validationSchema = Yup.object().shape({
        username: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(20, 'Must be at most 20 characters')
            .required('Username is required'),
        role: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(20, 'Must be at most 20 characters')
            .required('Role is required'),
    });


    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);

        setTimeout(async () => {
            try {
                const result = await Auth.currentAuthenticatedUser();
                console.log('successfully username:', result?.username);

                //fetching data from APPSYNC
                const fetchFunction = async () => await fetchGetOneUser(result?.username);
                const { username } = await fetchFunction();
                //fetching data from APPSYNC

                if (!username) {
                    await handleCreateUser({
                        username: values.username,             //username
                        cognitoUsername: result.username,      //cognitoUsername
                        role: values.role                      //role
                    });

                    //fetching data from APPSYNC
                    const fetchFunction = async () => await fetchGetOneUser(result.username);
                    const { username, role } = await fetchFunction();
                    //fetching data from APPSYNC

                    dispatch(settingUSERNAME(username));
                    dispatch(settingROLE(role));
                }
                else {
                    dispatch(settingUSERNAME(username));
                }

                dispatch(settingISSIGNEDIN(true));
                dispatch(settingDISPLAY_USERNAME_PAGE(false));
            }
            catch (error) {
                console.log('error username:', error);
                toast.error(error.message.toString(), {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
            }
            finally {
                setLoading(false)
            }

            setSubmitting(false);
        }, 1500);
    }


    useEffect(() => {
        async function func() {
            try {
                const result = await Auth.currentAuthenticatedUser();
                console.log('successfully username:', result?.username);

                const { username, role } = await fetchGetOneUser(result?.username);

                if (username) {
                    dispatch(settingUSERNAME(username));
                    dispatch(settingROLE(role));
                    dispatch(settingISSIGNEDIN(true));
                    dispatch(settingDISPLAY_USERNAME_PAGE(false));
                }
                seFetchUserLoading(false)
            } catch (error) {
                console.log('error fetching data:', error);
                alert(error);
            }
        }

        func();
    }, []);


    if (username || fetchUserLoading) return <></>;


    return (
        <div className='flex justify-center font-poppins'>
            <Toaster />
            <div className='w-full md:w-[600px] min-h-screen flex flex-col px-5 pt-7'>
                <div className='flex gap-5 items-center'>
                    <div onClick={async () => {
                        try {
                            await Auth.signOut();
                            console.log('successfully logout');

                            dispatch(settingUSERNAME(""));
                            dispatch(settingISSIGNEDIN(false));
                            dispatch(settingDISPLAY_USERNAME_PAGE(false));

                            navigate("/");
                        }
                        catch (error) {
                            console.log('error logout:', error);
                            alert(error);
                        }
                    }}
                        className='w-[25px] h-[25px] bg-gray-300 rounded-full flex justify-center items-center'
                    >
                        <MdKeyboardArrowLeft className='font-bold text-sm' />
                    </div>
                    <h2 className='text-zinc-800 font-semibold tracking-wide'>Username</h2>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="rounded-md flex-grow flex flex-col gap-5 mt-10">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="username" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Username
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4'>
                                    <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the username"
                                    />
                                </div>
                                <ErrorMessage name="username" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="role" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Role
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    {/* Replace PiLockLight with a suitable icon for role selection */}
                                    <Field
                                        as="select" // Use "as" to specify that this is a select field
                                        name="role"
                                        id="role"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                    // defaultValue=''
                                    >
                                        <option value="" disabled>Select your role</option>
                                        <option value="business">Business</option>
                                        <option value="customer">Customer</option>
                                        {/* Add more roles as necessary */}
                                    </Field>
                                </div>
                                <ErrorMessage name="role" component="div" className="text-red-500 text-xs" />
                            </div>
                            <button
                                type="submit"
                                className="bg-primary text-white font-medium p-2 pl-4 flex justify-between items-center rounded-full w-[150px] text-center text-sm"
                                disabled={isSubmitting}
                            >
                                {
                                    loading ? 'loading ...' :
                                        'Submit'
                                }
                                <div className='w-[30px] h-[30px] flex justify-center items-center rounded-full bg-[#556FFF]'>
                                    <MdOutlineKeyboardArrowRight className='font-bold' />
                                </div>
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}