import React, { useState } from 'react';
import Profile from '../images/profile.jpg'
import { RiHome5Line, RiNotification2Line } from "react-icons/ri";
import { IoCreateOutline } from "react-icons/io5";
import { TbMenu } from "react-icons/tb";
import { PiSignOutBold } from 'react-icons/pi';
import { Link } from 'gatsby'

//AWS-AMPLIFY
import { Auth } from 'aws-amplify';

//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingISSIGNEDIN, settingROLE, settingUSERNAME } from '../context/contextapi';
function Sidebar() {

    const [{ username, role }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false)

    return (
        <div>
            <div className='flex justify-between items-center py-5'>
                <h2 className='font-semibold'>Logo</h2>
                <div className='flex items-center gap-5'>
                    <div className='relative'>
                        <span className='w-1 h-1 rounded-full bg-red-600 absolute -top-1 right-0'></span>
                        <RiNotification2Line className='text-xl' />
                    </div>
                    <div className='w-[25px] h-[25px] rounded-full overflow-hidden'>
                        <img src={Profile} className='object-cover' alt="profile" />
                    </div>
                    <TbMenu onClick={() => setOpen(true)} className='text-xl cursor-pointer' />
                </div>
            </div>
            <div onClick={() => setOpen(false)} className={`fixed w-full h-full top-0 left-0 ${open ? 'bg-[rgba(0,0,0,0.3)] z-40' : 'bg-[rgba(0,0,0,0)] -z-10'} transition-all duration-500`}></div>
            <div className={`fixed w-[90%] sm:w-[400px] px-4 ${open ? 'left-0' : '-left-[100%]'} overflow-hidden h-full top-0 py-7 bg-white z-50 transition-all duration-500`}>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <div className='w-[35px] h-[35px] rounded-full overflow-hidden'>
                            <img src={Profile} className='object-cover' alt="profile" />
                        </div>
                        <p className='text-sm text-zinc-800 font-semibold'>Rocco Gavin</p>
                    </div>
                    <div onClick={() => setOpen(false)} className='font-bold w-[25px] h-[25px] rounded-full bg-gray-200 flex justify-center items-center cursor-pointer'>x</div>
                </div>
                <div className='mt-10 flex flex-col gap-3'>
                    <Link to='/'>
                        <button className='flex items-center text-sm gap-3 px-3 p-2'>
                            <RiHome5Line className='text-lg' />
                            Home
                        </button>
                    </Link>
                    {
                        role === 'business' &&
                        <Link to='/create-post'>
                            <button className='flex items-center text-sm gap-3 px-3 p-2'>
                                <IoCreateOutline className='text-lg' />
                                Create Post
                            </button>
                        </Link>
                    }
                    <button
                        onClick={async () => {
                            try {
                                
                                dispatch(settingUSERNAME(""));
                                dispatch(settingISSIGNEDIN(false));
                                dispatch(settingROLE(""));
                                await Auth.signOut();
                                console.log('successfully logout');

                            }
                            catch (error) {
                                console.log('error logout:', error);
                                alert(error);
                            }
                        }}
                        className='flex items-center text-sm gap-3 px-3 p-2'
                    >
                        <PiSignOutBold className='text-lg' />
                        Sign Out
                    </button>
                </div>
            </div>
        </div >
    );
}

export default Sidebar;