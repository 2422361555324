//REACTJS
import React, { useState, useEffect } from 'react';
//GATSBY
import { navigate } from 'gatsby';
//REACT-ICONS
import { FaGoogle } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
//COMPONENTS
import Posts from '../components/Posts';
import Username from '../components/Username';
//AWS-AMPLIFY
import { Auth, Hub } from 'aws-amplify';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingDISPLAY_USERNAME_PAGE } from '../context/contextapi';

// Helmet 
import Helmet from "react-helmet";

export default function Home() {
    const [{ username, displayUsernamePage, isSignedin }, dispatch] = useStateValue();
    const [user, setUser] = useState('');


    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then((userData) => {
                        setUser(userData);
                        console.log('Signed In:', userData);
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
            }
        });

        getUser().then((userData) => {
            setUser(userData);
            console.log('Signed In:', userData);
        });
    }, []);


    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log('Not signed in'));
    }


    useEffect(() => {
        if (!displayUsernamePage) {
            console.log("user ==>>", user);
            if (user !== '') {
                if (!user) {
                    navigate("/login");
                }
            }
        }
    }, [user]);

    
    if (user && displayUsernamePage) return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Username />
        </>
    );

    if (!user) return <></>;

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Posts />
        </>
    )
}


export function Head() {
    return (
        <title> Home | MassageOpen </title>
    )
}