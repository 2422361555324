//REACTJS
import React, { useState, useEffect } from 'react';
//GATSBY
import { navigate } from 'gatsby';
//REACT-ICONS
import { FaTrash, FaEdit } from 'react-icons/fa';
import { RiNotification2Line, RiHome5Line, RiSearch2Line, RiAddFill, RiHeart3Line, RiUser4Line } from "react-icons/ri";
import { TbMenu } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { MdModeEdit } from "react-icons/md";
import { TbTrash } from "react-icons/tb";

//APPSYNC-MUTATIONS
import { handleDeletePost } from '../appsync_functions/mutations';
//APPSYNC-QUERIES
import { fetchGetAllPosts } from '../appsync_functions/queries';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingISSIGNEDIN, settingUSERNAME } from '../context/contextapi';

// Image
import Profile from '../images/profile.jpg'
import Post from '../images/post.jpg'

import Loader from './Loader';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // core Swiper
import 'swiper/css/navigation'; // navigation module

// Toast
import { Toaster, toast } from 'react-hot-toast';
// Link
import { Link } from "gatsby"
import Sidebar from './Sidebar';


let massages = [
    "All",
    "Thai Massage",
    "Swedish Massage",
    "Chinese Massage",
    "Shiatsu Massage",
    "Balinese Massage",
    "Ayurvedic Massage",
    "Deep Tissue Massage",
    "Sports Massage",
    "Hot Stone Massage",
    "Aromatherapy Massage",
    "Other"
]


export default function Posts() {
    const [{ username, role }, dispatch] = useStateValue();


    const [loading, setLoading] = useState(true);
    const [deletePostLoading, setDeletePostLoading] = useState('')
    const [selected, setSelected] = useState(['All'])


    //fetching data from APPSYNC
    const [getAllPosts, setGetAllPosts] = useState([]);
    console.log("getAllPosts ==>> ", getAllPosts);
    const fetchFunction = async () => {
        setLoading(true);
        setGetAllPosts(await fetchGetAllPosts(!selected.length || selected.includes('All') ? massages : selected));
        setLoading(false);
    }
    useEffect(() => {
        fetchFunction();
    }, [selected]);
    //fetching data from APPSYNC

    async function deletePost(id) {
        setDeletePostLoading(id)
        try {
            await handleDeletePost(id);
            fetchFunction()
            toast.success('Post deleted successfully', {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
        }
        catch (error) {
            toast.error(error.message.toString(), {
                duration: 4000,
                position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                style: {
                    backgroundColor: '#d9d9d9',
                    padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            });
        }
        finally {
            setDeletePostLoading('')
        }
    }


    const handleChange = (val) => {
        if (val === 'All') {
            // If "All" is selected, reset the selection to only include "All"
            setSelected(['All']);
        } else {
            setSelected((prev) => {
                if (prev.includes('All')) {
                    // Remove "All" from the selection if any specific option is selected
                    prev = prev.filter((item) => item !== 'All');
                }
    
                if (prev.includes(val)) {
                    // If the item is already selected, deselect it
                    const filtered = prev.filter((massage) => massage !== val);
                    return filtered.length > 0 ? filtered : ['All'];
                } else {
                    // Add the item to the selection
                    return [...prev, val];
                }
            });
        }
    };


    return (
        <div className="min-h-[100dvh] font-poppins">
            <Toaster />
            <div className="relative mx-auto h-[100dvh] px-4 w-[100%] md:!w-[700px] flex flex-col">
                <Sidebar />
                <div className='flex gap-2 flex-wrap w-full mt-5'>
                    {/* <span
                        key={i}
                        className={`text-sm font-medium cursor-pointer ${selected.includes('All') ? 'text-white bg-gradient-to-r from-[#EF7996] to-[#6670F4]' : 'text-black bg-[#F4F5F7]'} py-[7px] px-3 rounded-full`}
                        onClick={() => handleChange('All')}
                    >
                        All
                    </span> */}
                    {
                        massages.map((val, i) => {
                            const isSelected = selected.includes(val);
                            return (
                                <span
                                    key={i}
                                    className={`text-sm font-medium cursor-pointer ${isSelected ? 'text-white bg-gradient-to-r from-[#EF7996] to-[#6670F4]' : 'text-black bg-[#F4F5F7]'} py-[7px] px-3 rounded-full`}
                                    onClick={() => handleChange(val)}
                                >
                                    {val}
                                </span>
                            );
                        })
                    }
                </div>
                {
                    !loading ? (
                        <div className='flex-grow mt-5 pb-20'>
                            <div>
                                {
                                    !getAllPosts.length ?
                                        <div className='py-10 text-sm font-semibold text-center'>
                                            No Post Found
                                        </div>
                                        :
                                        <Swiper
                                            slidesPerView={1.2} // Display 6 slides per view
                                            spaceBetween={15}
                                            className="mySwiper"
                                        >
                                            {
                                                getAllPosts && getAllPosts.map((item, i) => {
                                                    return (
                                                        <SwiperSlide key={i}>
                                                            <div className='w-full border-[0.9px] border-gray-300 rounded-xl'>
                                                                <div className='p-4'>
                                                                    <div className='flex justify-between items-center'>
                                                                        <div className='flex gap-2 items-center'>
                                                                            <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                                                                                <img src={Profile} className='w-full h-full object-cover' alt="profile" />
                                                                            </div>
                                                                            <p className='text-[12px] text-zinc-800 font-[500]'>{item.author}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='h-[160px] w-full rounded-xl overflow-hidden mt-3'>
                                                                        <img src={Post} className='w-full h-full object-cover' alt="profile" />
                                                                    </div>
                                                                    <div className='mt-3 h-[50px]'>
                                                                        <div className='flex justify-between items-center'>
                                                                            <p className='font-[500] text-zinc-800 capitalize'>{item.title}</p>
                                                                            {
                                                                                username === item.author &&
                                                                                <div className='flex gap-1'>
                                                                                    <span onClick={() =>
                                                                                        navigate("/update-post",
                                                                                            {
                                                                                                state: {
                                                                                                    id: item.id,
                                                                                                    title: item.title,
                                                                                                    author: item.author,
                                                                                                    description: item.description,
                                                                                                    //:item.//GPS
                                                                                                    latitude: item.latitude,
                                                                                                    longitude: item.longitude,
                                                                                                    location: item.location,
                                                                                                    city: item.city,
                                                                                                    country: item.country,
                                                                                                    massageType: item.massageType
                                                                                                }
                                                                                            }
                                                                                        )}
                                                                                        className='bg-[#f4f5f7] w-[30px] h-[30px] rounded-full flex justify-center items-center'
                                                                                    >
                                                                                        <MdModeEdit className='text-zinc-700' />
                                                                                    </span>
                                                                                    <span onClick={() => !deletePostLoading ? deletePost(item.id) : null} className='bg-red-400 cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center'>
                                                                                        {
                                                                                            deletePostLoading === item.id ?
                                                                                                <Loader height={'h-3'} width={'w-3'} /> :
                                                                                                <TbTrash className='text-white' />
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <p className='text-[12px] text-zinc-600 line-clamp-1 capitalize'>{item.description}</p>
                                                                    </div>
                                                                    <div className='flex justify-end mt-3'>
                                                                        <div className='flex items-center gap-2'>
                                                                            <SlLocationPin className='text-sm text-zinc-800' />
                                                                            <p className='text-xs text-zinc-600'>{item.city}, {item.country}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* {
                                                                    username === item.author &&
                                                                    <div className='border-t-[0.9px] border-zinc-300 p-3 flex justify-between'>
                                                                        <button className='text-zinc-800 text-xs font-[500] bg-[#f4f5f7] py-2 px-7 rounded-full'>Edit</button>
                                                                        <button className='text-white text-xs font-[500] bg-red-400 py-2 px-7 rounded-full'>Delete</button>
                                                                    </div>
                                                                } */}
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                }

                            </div>
                        </div>
                        // <>
                        //     <div className="text-center font-bold text-3xl sm:text-5xl mb-6 text-black"> All Posts </div>
                        //     <div className="flex items-center justify-center w-[80%] pb-2 m-auto -mt-2 sm:mt-0 mb-3 sm:mb-5">
                        //         <button
                        //             className="bg-green-500 text-white text-xs sm:text-base font-medium px-3 sm:px-6 py-2 sm:py-3 rounded-md flex items-center justify-center transition-all duration-200 hover:shadow-lg"
                        //             onClick={() => navigate("/create-post")}
                        //         >
                        //             Create Post
                        //         </button>
                        //         <button
                        //             className="bg-yellow-500 text-white text-xs sm:text-base font-medium px-3 sm:px-6 py-2 sm:py-3 rounded-md flex items-center justify-center transition-all duration-200 hover:shadow-lg ml-2"
                        //             onClick={async () => {
                        //                 try {
                        //                     await Auth.signOut();
                        //                     console.log('successfully logout');

                        //                     dispatch(settingUSERNAME(""));
                        //                     dispatch(settingISSIGNEDIN(false));
                        //                 }
                        //                 catch (error) {
                        //                     console.log('error logout:', error);
                        //                     alert(error);
                        //                 }
                        //             }}
                        //         >
                        //             Logout
                        //         </button>
                        //     </div>

                        //     {
                        //         getAllPosts.length > 0 ? (
                        //             <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-4">
                        //                 {
                        //                     getAllPosts.map((
                        //                         { id, title, author, description, latitude, longitude, location, city, country },
                        //                         index
                        //                     ) => (
                        //                         <div key={id} className="bg-white rounded-lg shadow-lg p-4 pb-6 my-0.5">
                        //                             <div className="flex items-center mb-2 text-sm sm:text-xl lg:text-2xl text-justify">
                        //                                 Post #
                        //                                 <div className="bg-gray-200 text-gray-600 rounded-full w-5 h-5 lg:w-8 lg:h-8 flex items-center justify-center ml-1.5">
                        //                                     <span className="text-xs lg:text-base mt-0.5 font-bold">{index + 1}</span>
                        //                                 </div>
                        //                             </div>

                        //                             <div className="mt-3 sm:mt-4 lg:mt-6 mb-7 animate-zoom-in duration-500 ease-out">
                        //                                 <h4 className="text-sm sm:text-xl lg:text-2xl text-justify font-bold mb-0.5"> Country : </h4>
                        //                                 <p className="text-xs sm:text-lg lg:text-xl text-justify font-normal text-gray-700 mb-2 break-words">{country}</p>
                        //                                 <h4 className="text-sm sm:text-xl lg:text-2xl text-justify font-bold mb-0.5"> City : </h4>
                        //                                 <p className="text-xs sm:text-lg lg:text-xl text-justify font-normal text-gray-700 mb-2 break-words">{city}</p>
                        //                                 <h2 className="text-sm sm:text-xl lg:text-2xl text-justify font-bold mb-0.5"> Title : </h2>
                        //                                 <p className="text-xs sm:text-lg lg:text-xl text-justify font-medium text-gray-700 mb-2 break-words">{title}</p>
                        //                                 <h3 className="text-sm sm:text-xl lg:text-2xl text-justify font-bold mb-0.5 ">Author : </h3>
                        //                                 <p className="text-xs sm:text-lg lg:text-xl text-justify font-medium text-gray-700 mb-2 break-words">{author}</p>
                        //                                 <h4 className="text-sm sm:text-xl lg:text-2xl text-justify font-bold mb-0.5"> Description : </h4>
                        //                                 <p className="text-xs sm:text-lg lg:text-xl text-justify font-normal text-gray-700 mb-2 break-words">{description}</p>
                        //                             </div>

                        //                             <div className="-mt-1 -ml-0.5">
                        //                                 <button
                        //                                     className="bg-red-500 text-white font-medium py-1 px-2 sm:py-2 sm:px-4 rounded-md w-full flex items-center justify-center transition-all duration-200 hover:shadow-lg mt-6 sm:mt-5 text-xs sm:text-sm"
                        //                                     onClick={async () => {

                        //                                         if (
                        //                                             username === author
                        //                                         ) {
                        //                                             await handleDeletePost(id);

                        //                                             const fetchFunction = async () => {
                        //                                                 setLoading(true);
                        //                                                 setGetAllPosts(await fetchGetAllPosts());
                        //                                                 setLoading(false);
                        //                                             }
                        //                                             fetchFunction();
                        //                                         }

                        //                                         else {
                        //                                             alert("Sorry, You're not allowed to Delete someone's post!");
                        //                                         }

                        //                                     }}
                        //                                 >
                        //                                     <FaTrash className="inline-block mr-2" /> Delete
                        //                                 </button>
                        //                                 <button
                        //                                     className="bg-blue-500 text-white font-medium py-1 px-2 sm:py-2 sm:px-4 rounded-md w-full flex items-center justify-center transition-all duration-200 hover:shadow-lg mt-2 text-xs sm:text-sm"
                        //                                     onClick={() => {

                        //                                         if (
                        //                                             username === author
                        //                                         ) {
                        //                                             navigate("/update-post",
                        //                                                 {
                        //                                                     state: {
                        //                                                         id,
                        //                                                         title,
                        //                                                         author,
                        //                                                         description,
                        //                                                         //GPS
                        //                                                         latitude,
                        //                                                         longitude,
                        //                                                         location,
                        //                                                         city,
                        //                                                         country
                        //                                                     }
                        //                                                 }
                        //                                             )

                        //                                             const fetchFunction = async () => {
                        //                                                 setLoading(true);
                        //                                                 setGetAllPosts(await fetchGetAllPosts());
                        //                                                 setLoading(false);
                        //                                             }
                        //                                             fetchFunction();
                        //                                         }

                        //                                         else {
                        //                                             alert("Sorry, You're not allowed to Update someone's post!");
                        //                                         }

                        //                                     }}
                        //                                 >
                        //                                     <FaEdit className="inline-block mr-2" /> Update
                        //                                 </button>
                        //                             </div>
                        //                         </div>
                        //                     ))
                        //                 }
                        //             </div>
                        //         ) : (
                        //             <div className="text-center font-bold text-3xl sm:text-5xl mb-6 text-black"> ( No Post Exist ) </div>
                        //         )
                        //     }
                        // </>
                    ) : (
                        <div className='flex-grow flex justify-center items-center'>
                            <Loader height='h-4' width='w-4' />
                        </div>
                    )
                }
                <div className='py-3 flex justify-between items-center bg-white sticky bottom-0 left-0 px-4 !z-30'>
                    <div><RiHome5Line className='text-xl' /></div>
                    <div><RiSearch2Line className='text-xl' /></div>
                    {
                        role === 'business' &&
                        <Link to='/create-post'><div className='w-[35px] h-[35px] bg-primary rounded-full flex justify-center items-center'><RiAddFill className='text-xl text-white' /></div></Link>
                    }
                    <div><RiHeart3Line className='text-xl' /></div>
                    <div><RiUser4Line className='text-xl' /></div>
                </div>
            </div>
        </div >
    )
}